import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import imageLocationPoint from 'images/icons/i-location-point.svg';

const AddressWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #5fd2da;
  padding: 13px 5.15px 13px 5.15px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .address {
    display: flex;

    img {
      margin-right: 5px;
    }

    .address-input {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      background: transparent;
      border: 0;
      outline: none;
    }
  }

  .store {
    font-weight: bold;
    flex: 0 0 100%;
    color: #333333;
    margin-left: 29px;
  }
`;

const FloorWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 8px;
`;

const FloorInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border: 1px solid #5fd2da;
  border-radius: 3px;
`;

const AddressBlock = ({
  address,
  floor,
  setFloor,
  setShowAddressSearch,
  storeName,
}) => {
  const [input, setInput] = useState(address);

  useEffect(() => {
    setInput(address);
  }, [address]);

  return (
    <>
      <AddressWrapper>
        <div className="address">
          <img src={imageLocationPoint} />
          <div
            className="address-input"
            placeholder="請輸入地址"
            onClick={() => setShowAddressSearch(true)}
          >
            {input}
          </div>
        </div>
        <div className="store">{storeName}</div>
      </AddressWrapper>
      <FloorWrapper>
        <FloorInput
          placeholder="樓層或房號"
          value={floor}
          onChange={(e) => setFloor(e.target.value)}
        />
      </FloorWrapper>
    </>
  );
};

export default AddressBlock;
