import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import GoogleMapFrame from 'components/google-map';

import AngleArrorLeft from 'images/icons/angle-arrow-left-black.svg';
import Gps from 'images/icons/gps-icon.png';

const AddressWrapper = styled.div`
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: #ffffff;
  border: 1px solid #5fd2da;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const AddressInput = styled.div`
  box-sizing: border-box;
  width: 80vw;
  min-height: 47px;
  padding: 12px;
`;

const BigMap = ({
  isBigMapShow,
  setIsBigMapShow,
  location,
  updateLocation,
  updateAddress,
  address,
  map8,
  gpsLatlng,
  setLocation,
}) => {
  const [bigMapLocation, setBigMapLocation] = useState(location);
  const [input, setInput] = useState(address);

  useEffect(() => {
    setInput(address);
  }, [isBigMapShow]);

  useEffect(() => {
    setInput(address);
  }, [address]);

  useEffect(() => {
    setInput(map8.formatted_address);
  }, [map8.formatted_address]);

  useEffect(() => {
    setBigMapLocation(location);
  }, [location]);

  return (
    <>
      {isBigMapShow && (
        <div className={'page' + (isBigMapShow ? ' show' : ' off')}>
          <div className="header-bar">
            <img
              className="header-cancel"
              src={AngleArrorLeft}
              onClick={() => {
                setIsBigMapShow(false);
                setInput(address);
              }}
            />
          </div>

          <div id="map">
            <AddressWrapper>
              <AddressInput>{input}</AddressInput>
            </AddressWrapper>

            <img
              className="gps"
              src={Gps}
              onClick={() => {
                setLocation(gpsLatlng);
                updateLocation(gpsLatlng);
              }}
            />

            <GoogleMapFrame
              zoom={16}
              location={bigMapLocation}
              updateLocation={updateLocation}
            />
          </div>

          <div className={`action-button ${isBigMapShow ? 'show' : ''}`}>
            <button onClick={updateAddress}>確認修改</button>
          </div>
        </div>
      )}

      <style jsx>{`
        .gps {
          display: flex;
          z-index: 1;
          position: fixed;
          margin-left: 310px;
          margin-top: 400px;
          margin-right: 17px;
        }

        .action-button {
          position: relative;
          &.show {
            position: fixed;
          }
        }

        #map {
          width: 100%;
          height: 612px;
          background-color: #aaa;
          position: relative;
        }

        .page {
          box-sizing: border-box;
          width: 100%;
          position: fixed;
          background-color: #fff;
          filter: drop-shadow(0px -4px 6px rgba(0, 0, 0, 0.161));
          transition: all 0.5s;
          &.show {
            top: 0;
            bottom: 0;
            z-index: 200;
            transform: translate3d(0, 0, 200);
            -webkit-transform: translate3d(0, 0, 200);
          }
        }

        .header-bar {
          height: 24px;
          width: 100%;
          padding: 0 0 20px;
          position: relative;
        }
        .header-cancel {
          position: absolute;
          top: 14px;
          left: 23px;
          display: inline-block;
          height: 23px;
          margin-right: 8px;
        }
      `}</style>
    </>
  );
};

export default BigMap;
