import React, { useState, useEffect } from 'react';
import { useInput, useSelectableList } from 'rooks';
import store, { actions } from 'redux/store';
import { ScrollElement } from 'react-scroll';
import ContentLoader from 'react-content-loader';

import Check from 'images/icons/round-check-fill.svg';
import twentyFourHourIcon from 'images/icons/ic-24h.svg';

import { STORE_TYPE } from 'utils/structure';
import { useAPI } from 'utils/api';
import { dismissLoading, showLoading } from 'utils/loading';

import AddressBlock from './AddressBlock';

import BottomSheet from '../bottom-sheet';
import GoogleMapFrame from '../google-map';
import AddressSearch from '../address-search';
import BigMap from '../big-map';

//新增門店卡片
const IN_APP = process.env.GATSBY_INAPP === 'true';

const StoreRadioPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  console.log('ratio: ' + ratio);
  return (
    <>
      <div className="store-radio-group">
        <ContentLoader
          width={129 * 5 * ratio} // 計算矩形和間距的總寬度
          height="94" // 修改矩形的高度
          viewBox={`0 0 ${390 * ratio} 94`} // 計算總視窗寬度
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          {[...Array(5)].map((i, index) => {
            return (
              <>
                <rect
                  key={index}
                  x={index * (129 + 11) * ratio} // 計算每個矩形的位置
                  y="0"
                  rx="16"
                  ry="16"
                  width={129 * ratio}
                  height="94" // 修改矩形的高度
                />
              </>
            );
          })}
        </ContentLoader>
      </div>

      <style jsx>{`
        .store-radio-group {
          display: flex;
          gap: 11px;
          padding-left: 11px;
          overflow-x: auto;
          padding-bottom: 8px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

const StoreRadioGroup = ({
  data,
  onChangeStore,
  selectStore,
  shippingModel,
  handleStoreRadioScrollTo,
}) => {
  return (
    <>
      <div
        className="store-radio-group"
        id="store-radio-group"
        name="store-radio-group"
      >
        {data.map((item, index) => (
          // <StoreRadio
          <StoreRadioElement
            key={index}
            data={item}
            onChangeStore={onChangeStore}
            selectStore={selectStore}
            shippingModel={shippingModel}
            handleStoreRadioScrollTo={handleStoreRadioScrollTo}
          />
        ))}
      </div>
      <style jsx>{`
        .store-radio-group {
          display: flex;
          gap: 11px;
          padding-left: 11px;
          overflow-x: auto;
          padding-bottom: 8px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
const StoreRadio = ({
  data,
  onChangeStore,
  selectStore,
  shippingModel,
  handleStoreRadioScrollTo,
}) => {
  return (
    <>
      <label
        key={data.id}
        className="store-radio"
        htmlFor={data.id}
        id={`store-item-${data.id}`}
        name={`store-item-${data.id}`}
        onClick={() => handleStoreRadioScrollTo(data.id)}
      >
        <input
          className="store-input"
          type="radio"
          id={data.id}
          name="store"
          onChange={onChangeStore}
          checked={selectStore === data.id}
        />
        <div className="check-input">
          <img src={Check} alt="checked" className="checked" />
        </div>
        <p className="store-name">{data.name}</p>
        {data['24h'] ? (
          <img
            className="hr-icon"
            src={twentyFourHourIcon}
            alt="twentyFourHourIcon"
          />
        ) : (
          <></>
        )}
        <p className="store-type">{STORE_TYPE[data.c_sst_type]}</p>

        {shippingModel === '1' ? (
          <p className="store-info">外送費${data.shipmentCost}</p>
        ) : undefined}
        {/* <p className="store-info">{data.distance}公里</p> */}
        <p className="store-info">{data.estimationTime}</p>
      </label>
      <style jsx>{`
        .store-radio {
          margin-right: 10px;
          position: relative;
          display: inline-block;
          width: 113px;
          padding: 10px 9px 5px 7px;
          border-radius: 16px;
          background-color: #fff;
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
          flex-shrink: 0;
        }
        .hr-icon {
          margin-right: 5px;
        }
        .store-type {
          display: inline-block;
          color: #999;
          font-size: 12px;
          line-height: 17px;
        }
        .store-input {
          display: none;
        }
        .check-input {
          position: absolute;
          right: 9px;
          top: 9px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 100%;
          border: 1px solid #e3e3e3;
        }
        .checked {
          display: none;
        }
        .store-input:checked ~ .check-input {
          width: 14px;
          height: 14px;
          border: none;
          .checked {
            display: block;
          }
        }
        .store-top {
        }
        .store-name {
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 3px;
        }
        .store-info {
          color: #999;
          font-size: 12px;
          line-height: 17px;
        }
      `}</style>
    </>
  );
};
const StoreRadioElement = ScrollElement(StoreRadio);

const NewAddress = ({
  isShow,
  data,
  setIsShow,
  clickUpdate,
  nearByStores,
  onChangeStore,
  selectStore,
  handleStoreRadioScrollTo,
  postData,
  newAddress,
  newAddressKey,
  setSelectStore,
  gpsLatlng,
  memberProfile,
  AddrFloor,
}) => {
  const api = useAPI();
  // location
  const [location, setLocation] = useState({ lat: 25.04, lng: 121.51 });
  const zoom = 16;

  const nameInput = useInput(
    newAddressKey ? memberProfile.name : data.fullName
  );

  const phoneInput = useInput(newAddressKey ? memberProfile.phone : data.phone);
  const tagInput = useInput(data.tag);
  const remarkInput = useInput(data.deliveryNote);
  const [address, setAddress] = useState(
    data.adjustedAddress && data.adjustedAddress != ''
      ? data.adjustedAddress
      : data.address
  );
  const [floor, setFloor] = useState(newAddressKey ? AddrFloor : data.floor);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [elevatorSelect, elevatorHandler] = useSelectableList(
    [true, false],
    data.deliveryIsElevator ? 0 : 1
  );

  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [receiveTypeValue, setReceiveTypeValue] = useState('5');

  const [updateAddressData, setUpdateAddressData] = useState(data);
  const [isBigMapShow, setIsBigMapShow] = useState(false);
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [storeName, setStoreName] = useState();
  const [district, setDistrict] = useState('');
  const [canSave, setCanSave] = useState(false);

  const [map8, setMap8] = useState({});

  const updateLocation = (mLocation) => {
    setLocation(mLocation);
    const latlng = `${mLocation.lat},${mLocation.lng}`;
    api
      .getGpsLocation(latlng)
      .then((res) => {
        setMap8(res[0]);
      })
      .catch((err) => {});
  };

  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        setReceiveTypeOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLocationFromServer = (lat, lng, addr) => {
    showLoading();
    setCanSave(false);
    store.dispatch(actions.setNearByStores([]));
    api
      .getStoreByLocation(lat, lng, addr)
      .then((res) => {
        if (!res) {
          // 清除門店
          setAddress(addr);
          setSelectStore('');
          setStoreName('');
          dismissLoading();
          return;
        }

        setCanSave(true);

        setLocation({
          lat: res.query.latitude,
          lng: res.query.longitude,
        });
        setAddress(
          `${
            res.query.adjusted_address !== ''
              ? res.query.adjusted_address
              : addr
          }`
        );
        setUpdateAddress(!updateAddress);
        store.dispatch(actions.setNearByStores(res.rows));
        setDistrict(res.query.district);

        const result = res.rows.find(
          (store, index) => store.id === data.defaultStoreId
        );

        //判斷是不是新地址以及預設門店是否為空，是的話取第一個門店，不是取預設門店
        if (
          newAddressKey ||
          data.defaultStoreId === '' ||
          result === undefined ||
          result === null
        ) {
          handleStoreRadioScrollTo(res.rows[0].id);
          setSelectStore(res.rows[0].id);
        } else {
          handleStoreRadioScrollTo(data.defaultStoreId);
          setSelectStore(data.defaultStoreId);
        }

        // 3碼郵遞區號
        setUpdateAddressData((prevState) => ({
          ...prevState,
          postalCode_3: res.query.postalCode_3,
        }));

        console.log('update address data', updateAddressData);

        dismissLoading();
      })
      .catch((error) => {
        console.log('get location by server exception =', error);
      });
  };

  const handleAddressChange = () => {
    setFloor('');
    const formatted_address = map8.formatted_address;
    setAddress(formatted_address);
    setIsBigMapShow(false);
    getLocationFromServer(null, null, formatted_address);
  };

  const onSave = () => {
    clickUpdate({
      ...updateAddressData,
      district: district,
    });
  };
  useEffect(() => {
    if (isShow) {
      if (newAddressKey) {
        getOptions();
        getLocationFromServer(null, null, newAddress);
      } else {
        getOptions();
        getLocationFromServer(
          null,
          null,
          data.adjustedAddress && data.adjustedAddress != ''
            ? data.adjustedAddress
            : data.address
        );
        setReceiveTypeValue(data.deliveryPointValue);
      }
    }
  }, [data]);
  useEffect(() => {
    setUpdateAddressData((prevState) => ({
      ...prevState,
      fullName: nameInput.value,
      phone: phoneInput.value,
      floor: floor,
      tag: tagInput.value,
      deliveryNote: remarkInput.value,
      deliveryIsElevator: elevatorSelect[1],
      deliveryPointValue: receiveTypeValue,
      address:
        floor === '' || floor === undefined ? address : `${address}${floor}`,
      defaultStoreId: selectStore,
    }));

    console.log('update address data', updateAddressData);
  }, [
    nameInput.value,
    phoneInput.value,
    floor,
    // companyInput.value,
    tagInput.value,
    remarkInput.value,
    address,
    elevatorSelect[0],
    receiveTypeValue,
    selectStore, //receiveMethodSelect
  ]);
  useEffect(() => {
    if (address && address !== '') getLocationFromServer(null, null, address);
  }, [address]);
  useEffect(() => {
    const result = nearByStores.find(
      (store, index) => store.id === selectStore
    );
    if (result) {
      setStoreName(result.name);
    }
  }, [selectStore]);
  return (
    <>
      <BottomSheet title="地址資訊" isShow={isShow} setIsShow={setIsShow}>
        <div className="sheet">
          <div
            id="map"
            onClick={() => {
              setIsBigMapShow(true);
            }}
          >
            <GoogleMapFrame location={location} zoom={zoom} />
          </div>
          <div className="store-list">
            {nearByStores.length > 0 ? (
              <div>
                <StoreRadioGroup
                  shippingModel={IN_APP ? postData.current.shippingModel : '1'}
                  data={nearByStores}
                  onChangeStore={onChangeStore}
                  selectStore={selectStore}
                  handleStoreRadioScrollTo={handleStoreRadioScrollTo}
                />{' '}
              </div>
            ) : (
              <div>
                <div className="store-preview">
                  <StoreRadioPreview></StoreRadioPreview>
                </div>
              </div>
            )}
          </div>
          <div className="form">
            <h2>收件人</h2>
            <input type="text" placeholder="請輸入姓名" {...nameInput} />
            <h2>電話</h2>
            <input
              type="text"
              placeholder="請輸入電話"
              {...phoneInput}
              maxLength={10}
            />
            <h2>地點</h2>
            <AddressBlock
              address={address}
              floor={floor}
              setFloor={setFloor}
              setShowAddressSearch={setShowAddressSearch}
              storeName={storeName}
            />
            <h2>地址標籤</h2>
            <input type="text" placeholder="標籤" {...tagInput} />
            <div className="radios">
              <span className="radio-name">電梯</span>
              <input
                type="radio"
                name="elevator"
                id="y"
                checked={elevatorHandler.matchSelection({ index: 0 })}
                onChange={() => elevatorHandler.toggleSelection({ index: 0 })()}
              />
              <label htmlFor="y">有</label>
              <input
                type="radio"
                name="elevator"
                id="n"
                checked={elevatorHandler.matchSelection({ index: 1 })}
                onChange={() => elevatorHandler.toggleSelection({ index: 1 })()}
              />
              <label htmlFor="n">無</label>
            </div>
            <div className="radios">
              <span className="radio-name">簽收</span>
              {receiveTypeOptions &&
                receiveTypeOptions.map((item, index) => (
                  <div key={`div-receive-${index}`}>
                    <input
                      type="radio"
                      id={`receive-${index}`}
                      name="sign"
                      value={item.value}
                      checked={item.value === receiveTypeValue}
                      onChange={(e) => {
                        setReceiveTypeValue(e.target.value);
                      }}
                    />
                    <label htmlFor={`receive-${index}`}>{item.name}</label>
                  </div>
                ))}

              <p className="notice">
                如果您的樓層有安全管制，請通知管理室協助簽收。
              </p>
            </div>
            <h2 className="memo">地址備註</h2>
            <textarea
              placeholder="樓層/房號、公司大樓名稱、其他地址備註"
              {...remarkInput}
            ></textarea>
            <div
              className={`action-button ${isShow ? 'show' : ''} ${
                !canSave ? 'disable' : ''
              }`}
            >
              <button onClick={onSave}>儲存並繼續</button>
            </div>
          </div>
        </div>
      </BottomSheet>
      <BigMap
        isBigMapShow={isBigMapShow}
        setIsBigMapShow={setIsBigMapShow}
        location={location}
        zoom={zoom}
        updateLocation={updateLocation}
        updateAddress={handleAddressChange}
        address={address}
        map8={map8}
        gpsLatlng={gpsLatlng}
        setLocation={setLocation}
      />
      <AddressSearch
        isShow={showAddressSearch}
        setShowblock={() => setShowAddressSearch(false)}
        setAddress={setAddress}
        setAddrFloor={setFloor}
        detailAddress={address}
      />
      <style jsx>{`
        .action-button {
          position: relative;
          &.show {
            position: fixed;
          }
          &.disable {
            button {
              pointer-events: none;
              background: #ccc;
              border: #ccc;
            }
          }
        }
        .sheet {
          height: calc(100vh - 91px);
          position: relative;
        }
        #map {
          width: 100%;
          height: 204px;
          background-color: #aaa;
          position: relative;
        }
        .form {
          position: relative;
          padding: 10px 18px 120px 22px;
        }
        h2 {
          padding-bottom: 8px;
        }
        input + h2 {
          padding-top: 11px;
        }
        .input-group {
          display: flex;
          gap: 8px;
          padding-top: 8px;
          padding-bottom: 20px;
        }
        input[type='text'] {
          width: 100%;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 14px 12px 13px 12px;
          &::placeholder {
            opacity: 0.5;
          }
        }
        .radios {
          padding-top: 12px;
          .notice {
            padding-top: 9px;
            font-size: 14px;
            line-height: 20px;

            color: #000000;
          }
        }
        input[type='radio'] {
          display: none;
        }
        .radio-name {
          font-size: 18px;
          line-height: 26px;
          color: #333333;
          padding-right: 10px;
        }
        input[type='radio'] + label {
          position: relative;
          font-size: 14px;
          line-height: 20px;
          padding-left: 26px;
          color: #000000;
          & ~ label {
            margin-left: 14px;
          }
        }
        input[type='radio'] + label::before {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background-color: #ffffff;
          border: solid 1px #d2d2d2;
          border-radius: 50%;
          box-sizing: border-box;
        }
        input[type='radio']:checked + label::before {
          border: solid 6px #5fd2da;
        }
        .memo {
          padding-top: 11px;
        }
        textarea {
          box-sizing: border-box;
          width: 100%;
          min-height: 70px;
          resize: none;
          border: none;
          outline: none;
          font-size: 14px;
          line-height: 20px;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 14px 12px 13px 12px;

          color: rgb(51, 51, 51);
          &::placeholder {
            color: rgba(51, 51, 51, 0.5);
          }
        }
        .store-list {
          overflow-x: scroll;
          margin-top: -64px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .store-preview {
          overflow-x: scroll;
          padding-bottom: 30px;
          &::-webkit-scrollbar {
            display: none;
          }
          transform: translateZ(1px);
        }
      `}</style>
    </>
  );
};

export default NewAddress;
