import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ImgRoundCheck from 'images/icons/round-fill.svg';
import ImgEdit from 'images/icons/i-edit.svg';

const LocationRadioWrapper = styled.label`
  position: relative;
  display: flex;
  padding-bottom: 12.5px;
  border-bottom: 1px solid #e4e4e4;
  align-items: center;
  justify-content: space-between;

  & ~ & {
    padding-top: 16.5px;
  }

  .text {
    flex-grow: 1;
    padding-left: 8px;
  }

  .edit {
    width: 30px;
    height: 30px;
    align-self: baseline;
  }
`;

const LocationRadio = ({
  data,
  setSelectData,
  setSelectEditData,
  setAnyAction,
  currentSelectData,
  currentInputAddress,
  setNewAddresses,
  setNewAddressKey,
  setSelectStore,
  getStore,
  getAddressDetail,
}) => {
  const [isCheck, setIsCheck] = useState(false);

  const onClickEdit = (event) => {
    event.preventDefault();
    setAnyAction(true);
    setNewAddresses('');
    setNewAddressKey(false);
    if (
      setSelectEditData !== undefined &&
      typeof setSelectData === 'function'
    ) {
      setSelectEditData({});
      getAddressDetail(data.addressId);
    }
  };

  const onChange = (event) => {
    if (setSelectData !== undefined && typeof setSelectData === 'function') {
      setAnyAction(true);
      setSelectData(data);
      setSelectStore(data.defaultStoreId);
      getStore(null, null, data.address);
    }
  };

  useEffect(() => {
    setIsCheck(currentSelectData?.addressId === data.addressId);
  }, [currentSelectData, data.addressId, currentInputAddress]);

  return (
    <>
      <LocationRadioWrapper htmlFor={data.addressId}>
        <div>
          <input
            className="location-input"
            type="radio"
            id={data.addressId}
            name="location"
            onChange={onChange}
            checked={isCheck}
          />
          <div className="check-input">
            <img src={ImgRoundCheck} alt="checked" className="checked" />
          </div>
        </div>
        <div className="text">
          <div style={{ fontWeight: '700' }} className="title">
            {data.tag === '' ? '最近使用' : data.tag}
          </div>
          <div className="address">{data.address}</div>
          <div
            style={{ fontSize: '14px', fontWeight: '700' }}
            className="title"
          >
            {data.defaultStoreName}
          </div>
        </div>
        <div className="edit" onClick={onClickEdit}>
          <img src={ImgEdit} alt="Edit" className="edit" />
        </div>
      </LocationRadioWrapper>
      <style jsx>{`
        .location-input {
          display: none;
        }

        .check-input {
          width: 14px;
          height: 14px;
          background-color: #fff;
          border-radius: 100%;
          border: 1px solid #e3e3e3;
        }

        .checked {
          display: none;
        }

        .location-input:checked ~ .check-input {
          width: 16px;
          height: 16px;
          border: none;

          .checked {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export default LocationRadio;
